import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AgentResponse {
  company?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface AllTimeCommitsRelatedRes {
  organization?: OrganizationResponse;
  sport?: SportResponseRecord;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export enum ClassYear {
  Unknown = 'Unknown',
  Freshman = 'Freshman',
  RedShirtFreshman = 'RedShirt_Freshman',
  Sophomore = 'Sophomore',
  RedShirtSophomore = 'RedShirt_Sophomore',
  Junior = 'Junior',
  RedShirtJunior = 'RedShirt_Junior',
  Senior = 'Senior',
  RedShirtSenior = 'RedShirt_Senior',
}

export interface CollectiveGroupFounderResponse {
  /** @format int64 */
  collectiveGroupKey: number;
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  linkedInHandle?: string | null;
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  personKey?: number | null;
  personSlug?: string | null;
  tikTokHandle?: string | null;
  title?: string | null;
  twitterHandle?: string | null;
}

export interface CollectiveGroupResponse {
  /** @format double */
  annualGoalAmount?: number | null;
  /** @format double */
  confirmedRaisedAmount?: number | null;
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  description?: string | null;
  founders?: CollectiveGroupFounderResponse[];
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  /** @format date-time */
  launchDate?: string | null;
  linkedInHandle?: string | null;
  mergedIntoGroup?: CollectiveGroupSimpleResponse;
  /** @format int64 */
  mergedIntoGroupKey?: number | null;
  missionStatement?: string | null;
  /** @minLength 1 */
  name: string;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  organizationType?: string | null;
  /** @minLength 1 */
  slug: string;
  socialAsset?: ImageResponse;
  /** @format int64 */
  socialAssetKey?: number | null;
  sports?: CollectiveGroupSportResponse[];
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  websiteName?: string | null;
  websiteUrl?: string | null;
  youtubeHandle?: string | null;
}

export interface CollectiveGroupSimpleResponse {
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  /** @format int64 */
  key: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  slug: string;
}

export interface CollectiveGroupSportResponse {
  /** @minLength 1 */
  name: string;
  primary: boolean;
  /** @format int64 */
  sportKey: number;
}

export interface DraftSimpleResponse {
  collegeOrganization?: OrganizationResponse;
  compensatory: boolean;
  forfeited: boolean;
  industryComparison?: PlayerRatingAllResponse[] | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  pick?: number | null;
  rosterRating?: RatingResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  sportKey: number;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export enum Gender {
  Unknown = 'Unknown',
  Womens = 'Womens',
  Mens = 'Mens',
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface Nil100FilterResponse {
  /** @format date-time */
  lastUpdated: string;
  organization?: OrganizationResponse;
  /** @format int32 */
  year?: number | null;
}

export interface NilCompanyResponse {
  assetUrl?: string | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  url?: string | null;
}

export interface NilDealResponse {
  agent?: AgentResponse;
  /** @format double */
  amount?: number | null;
  collectiveGroup?: CollectiveGroupSimpleResponse;
  company?: NilCompanyResponse;
  /** @format date-time */
  date?: string | null;
  detail?: SimpleHeadlineResponse;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  organization?: OrganizationResponse;
  person?: PlayerResponse;
  positions?: PositionResponse[] | null;
  rating?: RatingResponse;
  rosterRating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  sourceUrl?: string | null;
  sports?: SportResponseRecord[] | null;
  status?: RecStatusResponse;
  type?: string;
  verified: boolean;
}

export interface NilDealResponseCollectiveGroupResponseRelatedPagedData {
  list?: NilDealResponse[];
  pagination?: Pagination;
  relatedModel?: CollectiveGroupResponse;
}

export interface NilDealResponsePagedData {
  list?: NilDealResponse[];
  pagination?: Pagination;
}

export enum NilDealType {
  All = 'All',
  Person = 'Person',
  Organization = 'Organization',
}

export interface NilHundredResponse {
  person: NilPlayerListResponse;
  valuation: PersonValuationResponse;
}

export interface NilHundredResponseNil100FilterResponseRelatedPagedData {
  list?: NilHundredResponse[];
  pagination?: Pagination;
  relatedModel?: Nil100FilterResponse;
}

export interface NilPlayerListResponse {
  athleteVerified: boolean;
  /** @format int32 */
  classYear?: number | null;
  commitStatus?: Status;
  defaultAsset?: ImageResponse;
  defaultAssetUrl?: string | null;
  defaultSport?: SportBase;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  nilStatus: NilStatuses;
  /** @format double */
  nilValue?: number | null;
  organizationLevel?: string | null;
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRating?: RatingBase;
  slug?: string;
  sport?: SportBase;
  /** @format double */
  weight?: number | null;
}

export enum NilStatuses {
  Normal = 'Normal',
  Pending = 'Pending',
  Suspended = 'Suspended',
  Private = 'Private',
  Hidden = 'Hidden',
  Show = 'Show',
  Final = 'Final',
}

export interface OrganizationBase {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonConnectionFullResponse {
  connectedCollegeOrganization?: OrganizationResponse;
  connectedDraft?: DraftSimpleResponse;
  connectedPlayer?: SmallPlayerResponse;
  connectedRating?: RatingResponse;
  connectedRosterRating?: RatingResponse;
  connection?: PersonConnectionResponse;
}

export interface PersonConnectionResponse {
  /** @format int64 */
  connectedPersonKey: number;
  connectedPersonSport?: PersonSportResponseRecord;
  description?: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface PersonDefaultMeasurementsRes {
  formattedHeight?: string | null;
  /** @format double */
  height?: number | null;
  /** @format double */
  weight?: number | null;
}

export interface PersonInfluencerResponse {
  handle?: string | null;
  handshake: boolean;
  type?: string | null;
}

export interface PersonSocialValuationResponse {
  /** @format int64 */
  followers?: number | null;
  /** @format int64 */
  previousValuation?: number | null;
  profile?: PersonInfluencerResponse;
  socialType: SocialType;
  /** @format int64 */
  valuation?: number | null;
  /** @format int64 */
  valuationChange?: number | null;
}

export interface PersonSportResponseRecord {
  /** @format int64 */
  currentPersonSportRankingKey?: number | null;
  /** @format int64 */
  currentRosterPersonSportRankingKey?: number | null;
  defaultSport: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface PersonValuationResponse {
  /** @format int64 */
  followers?: number | null;
  groupName?: string | null;
  /** @format int64 */
  groupRank?: number | null;
  /** @format int64 */
  lastUpdated?: number | null;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  rosterValue?: number | null;
  socialValuations?: PersonSocialValuationResponse[];
  /** @format int64 */
  valuation?: number | null;
  /** @format int64 */
  valuationChange?: number | null;
}

export interface PlayerItemResponse {
  /**
   * Date a person decommitted. Only relevant when looking at decommits
   * @format date-time
   */
  dateDecommitted?: string | null;
  industryComparison?: PlayerRatingAllResponse[] | null;
  nilValue?: ValuationResponse;
  organization?: OrganizationResponse;
  player?: PlayerResponse;
  /** @format int64 */
  psoKey?: number | null;
  rating?: RatingResponse;
  /** @format int64 */
  recKey?: number | null;
  rosterRating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
  transferRating?: RatingResponse;
}

export interface PlayerItemResponsePagedData {
  list?: PlayerItemResponse[];
  pagination?: Pagination;
}

export interface PlayerItemResponsePagedDataPagedData {
  list?: PlayerItemResponsePagedData[];
  pagination?: Pagination;
}

export interface PlayerItemResponsePositionResponseRelatedPagedData {
  list?: PlayerItemResponse[];
  pagination?: Pagination;
  relatedModel?: PositionResponse;
}

export interface PlayerItemResponsePositionResponseRelatedPagedDataPagedData {
  list?: PlayerItemResponsePositionResponseRelatedPagedData[];
  pagination?: Pagination;
}

export interface PlayerItemVisitResponse {
  /**
   * Date a person decommitted. Only relevant when looking at decommits
   * @format date-time
   */
  dateDecommitted?: string | null;
  industryComparison?: PlayerRatingAllResponse[] | null;
  nilValue?: ValuationResponse;
  official: boolean;
  organization?: OrganizationResponse;
  player?: PlayerResponse;
  /** @format int64 */
  psoKey?: number | null;
  rating?: RatingResponse;
  /** @format int64 */
  recKey?: number | null;
  rosterRating?: RatingResponse;
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
  transferRating?: RatingResponse;
}

export interface PlayerItemVisitResponseRelatedVisitDateRelatedPagedData {
  list?: PlayerItemVisitResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedVisitDate;
}

export interface PlayerItemVisitResponseRelatedVisitDateRelatedPagedDataPagedData {
  list?: PlayerItemVisitResponseRelatedVisitDateRelatedPagedData[];
  pagination?: Pagination;
}

export interface PlayerListBase {
  athleteVerified: boolean;
  /** @format int32 */
  classYear?: number | null;
  commitStatus?: Status;
  defaultAsset?: ImageResponse;
  defaultAssetUrl?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  nilStatus: NilStatuses;
  /** @format double */
  nilValue?: number | null;
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingBase;
  /** @format int64 */
  recruitmentKey?: number | null;
  rosterRating?: RatingBase;
  slug?: string;
  sport?: SportBase;
  /** @format double */
  weight?: number | null;
}

export interface PlayerOrgRatingAllResponse {
  organization?: OrganizationResponse;
  ratings?: PlayerRatingAllResponse[] | null;
}

export interface PlayerOrgRatingAllResponsePagedData {
  list?: PlayerOrgRatingAllResponse[];
  pagination?: Pagination;
}

export interface PlayerRatingAllResponse {
  change?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  classYear?: number | null;
  fiveStarPlus: boolean;
  link?: string | null;
  nearlyFiveStarPlus: boolean;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  rankingType: RankingType;
  /** @format int32 */
  rankingYear: number;
  /** @format double */
  rating?: number | null;
  sport?: string | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PlayerResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: AssetResponse;
  defaultSport?: AttributeResponse;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  hometown?: AttributeResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  position?: AttributeResponse;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  state?: AttributeResponse;
  /** @format double */
  weight?: number | null;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface PredictionAccuracies {
  all?: PredictionAccuracy;
  year?: PredictionAccuracy;
}

export interface PredictionAccuracy {
  /** @format double */
  averageDaysCorrect?: number | null;
  /** @format double */
  correct: number;
  /** @format double */
  percentCorrect?: number | null;
  /** @format double */
  total: number;
}

export interface PredictionUserProfileResponseRecord {
  predictionAccuracy?: PredictionAccuracies;
  userRecord?: UserProfileResponseRecord;
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
}

export interface RatingBase {
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingExtendedResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  nearlyFiveStarPlus: boolean;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  sport?: AttributeResponse;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface RdbV2CollectiveGroupsPositionDealsDetailParams {
  /**
   * The collective group key for the
   * @format int64
   */
  cgKey: number;
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface RdbV2DecommitsLatestListParams {
  /**
   * @format int32
   * @default 50
   */
  limit?: number;
  /** @format int32 */
  orgKey?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  sport?: number;
  /** @format int32 */
  year?: number;
}

export interface RdbV2LatestListParams {
  /**
   * @format int32
   * @default 50
   */
  limit?: number;
  /** @format int32 */
  orgKey?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  sport?: number;
  /** @format int32 */
  year?: number;
}

export interface RdbV2Nil100ListParams {
  /**
   * Pagination limit, highest and default is 100
   * @format int32
   * @min 1
   * @max 100
   * @default 100
   */
  limit?: number;
  /**
   * Optional Orginization Key
   * @format int32
   */
  orgKey?: number;
  /**
   * Optionl Pagination page default 1
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * Recruitment year
   * @format int32
   */
  year?: number;
}

export interface RdbV2OrganizationsAllTimeCommitsDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV2OrganizationsCommitsDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
  /**
   * The year
   * @format int32
   */
  year?: number;
}

export interface RdbV2OrganizationsDecommitsDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
  /**
   * The year
   * @format int32
   */
  year?: number;
}

export interface RdbV2OrganizationsOffersDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
  /**
   * The year
   * @format int32
   */
  year?: number;
}

export interface RdbV2OrganizationsTransfersDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The page to return
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
  /**
   * The year
   * @format int32
   */
  year?: number;
}

export interface RdbV2OrganizationsVisitsDetailParams {
  /**
   * The org key
   * @format int32
   */
  orgKey: number;
  /**
   * The sport key
   * @format int32
   */
  sportKey?: number;
  /**
   * The year
   * @format int32
   */
  year?: number;
}

export interface RdbV2PlayerAllRankingsDetailParams {
  /**
   * The player key
   * @format int64
   */
  personKey: number;
  /** @format int32 */
  sportKey?: number;
}

export interface RdbV2UsersPredictionsDetailParams {
  /**
   * @format int32
   * @default 50
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
  /** @format int64 */
  userKey: number;
  /** @format int32 */
  year?: number;
}

export interface RecStatusResponse {
  committedAsset?: OrganizationResponse;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  draft: boolean;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  /** @format date-time */
  transferWithdrawn?: string | null;
  transferredAsset?: OrganizationResponse;
  type?: string | null;
}

export interface RelatedVisitDate {
  date?: string;
  status?: string;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RosterCompRes {
  collPso?: SimplePsoRes;
  /** @format int64 */
  collPsoKey?: number | null;
  draftInfo?: SimpleDraftPickRes;
  /** @format int64 */
  draftPickKey?: number | null;
  /** @format int64 */
  hsPsoKey?: number | null;
  /** @format int64 */
  hsPsrKey?: number | null;
  hsRec?: RecStatusResponse;
  /** @format int64 */
  hsRecKey?: number | null;
  hsRecRanking?: SimplePsrRes;
  hsRpm?: RpmPercentResponse[] | null;
  person?: SimplePersonResponse;
  personDefaultAsset?: AssetResponse;
  personDefaultMeasurements?: PersonDefaultMeasurementsRes;
  /** @format int64 */
  personKey: number;
  position?: PositionResponse;
  /** @format int32 */
  positionKey?: number | null;
}

export interface RosterCompResAllTimeCommitsRelatedResRelatedPagedData {
  list?: RosterCompRes[];
  pagination?: Pagination;
  relatedModel?: AllTimeCommitsRelatedRes;
}

export interface RpmPercentResponse {
  /** @format double */
  distance?: number | null;
  organization?: OrganizationResponse;
  /** @format double */
  percent?: number | null;
  showRpmPercentage?: boolean | null;
}

export interface RpmPickList {
  pick?: RpmPicks;
  player?: PlayerListBase;
}

export interface RpmPickListPredictionUserProfileResponseRecordRelatedPagedData {
  list?: RpmPickList[];
  pagination?: Pagination;
  relatedModel?: PredictionUserProfileResponseRecord;
}

export interface RpmPicks {
  articleLink?: string | null;
  /** @format double */
  confidence?: number | null;
  correct?: boolean | null;
  /** @format date-time */
  dateAdded: string;
  /** @format double */
  daysCorrect?: number | null;
  description?: string | null;
  expert?: UserProfileBase;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  premium: boolean;
  /** @format double */
  previousConfidence?: number | null;
  /** @format date-time */
  previousDateAdded?: string | null;
  topTeams?: RpmSummaryChangeResponse[] | null;
  type: RpmType;
  /** @format int32 */
  year: number;
}

export interface RpmResponse {
  committed: boolean;
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  percent?: number | null;
  status?: string | null;
  /** @format date-time */
  statusDate?: string | null;
}

export interface RpmSummaryChangeResponse {
  /** @format double */
  currentPercentage?: number | null;
  /** @format int32 */
  currentRank?: number | null;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  previousPercentage?: number | null;
  /** @format int32 */
  previousRank?: number | null;
}

export enum RpmType {
  Pick = 'Pick',
  ConfidenceChange = 'ConfidenceChange',
  NewLeader = 'NewLeader',
  PercentChange = 'PercentChange',
}

export interface SimpleDraftPickRes {
  compensatory: boolean;
  /** @format int64 */
  draftKey: number;
  forfeited: boolean;
  /** @format int64 */
  key: number;
  organization?: SimpleOrganizationResponse;
  organizationDefaultAsset?: AssetResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  round: number;
  /** @format int32 */
  roundPick?: number | null;
  /** @format int32 */
  sportKey: number;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimpleImageResponseRecord {
  /** @format int64 */
  key: number;
  url?: string | null;
}

export interface SimpleOrganizationResponse {
  abbreviation?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  organizationType: OrganizationType;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SimplePsoRes {
  classYear?: ClassYear;
  /** @format int64 */
  key: number;
  organization?: SimpleOrganizationResponse;
  organizationAsset?: AssetResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  year: number;
}

export interface SimplePsrRes {
  /** @format int32 */
  industryOverallRank?: number | null;
  /** @format int32 */
  industryPositionRank?: number | null;
  /** @format double */
  industryRating?: number | null;
  /** @format int32 */
  industryStars: number;
  /** @format int32 */
  industryStateRank?: number | null;
  /** @format int64 */
  key: number;
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export enum SocialType {
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
}

export interface SportBase {
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface Status {
  classRank?: string | null;
  committedAsset?: KeyUrlBase;
  committedAssetRes?: AssetResponse;
  committedOrganization?: OrganizationBase;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  transferredAsset?: KeyUrlBase;
  transferredAssetRes?: AssetResponse;
  type?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface Team {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: KeyUrlBase;
  fullName?: string | null;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface UserProfileBase {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string | null;
  profilePicture?: KeyUrlBase;
  profilePictureResponse?: ImageResponse;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface UserProfileResponseRecord {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string | null;
  profilePicture?: SimpleImageResponseRecord;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface V2DealsListParams {
  gender?: Gender;
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  type?: NilDealType;
}

export interface V2OrganizationsDealsDetailParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /** @format int32 */
  organizationKey: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V2PeopleDealsDetailParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The person key for the player
   * @format int64
   */
  personKey: number;
}

export interface V2PlayersDealsDetailParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The person key for the player
   * @format int64
   */
  personKey: number;
}

export interface ValuationResponse {
  /** @format int64 */
  groupRank?: number | null;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  totalValue?: number | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags CollectiveGroup
 * @name RdbV2CollectiveGroupsPositionDealsDetail
 * @summary Gets all Position NIL Deals for a Collective
 * @request GET:/public/rdb/v2/collective-groups/{cgKey}/position-deals
 */
export const rdbV2CollectiveGroupsPositionDealsDetail = (
  client: AxiosInstance,
  { cgKey, ...query }: RdbV2CollectiveGroupsPositionDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealResponseCollectiveGroupResponseRelatedPagedData>({
      url: `/public/rdb/v2/collective-groups/${cgKey}/position-deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2DecommitsLatestList
 * @summary Gets Latest Decommits
 * @request GET:/public/rdb/v2/decommits/latest
 */
export const rdbV2DecommitsLatestList = (
  client: AxiosInstance,
  query: RdbV2DecommitsLatestListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemResponsePagedData>({
      url: `/public/rdb/v2/decommits/latest`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2LatestList
 * @summary Gets Latest Commits
 * @request GET:/public/rdb/v2/latest
 */
export const rdbV2LatestList = (
  client: AxiosInstance,
  query: RdbV2LatestListParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemResponsePagedData>({
      url: `/public/rdb/v2/latest`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonInfluencer
 * @name RdbV2Nil100List
 * @summary Gets Nil Rankings, version 2-paginated
 * @request GET:/public/rdb/v2/nil-100
 */
export const rdbV2Nil100List = (
  client: AxiosInstance,
  query: RdbV2Nil100ListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilHundredResponseNil100FilterResponseRelatedPagedData>({
      url: `/public/rdb/v2/nil-100`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2OrganizationsAllTimeCommitsDetail
 * @request GET:/public/rdb/v2/organizations/{organizationKey}/all-time-commits
 */
export const rdbV2OrganizationsAllTimeCommitsDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV2OrganizationsAllTimeCommitsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RosterCompResAllTimeCommitsRelatedResRelatedPagedData>({
      url: `/public/rdb/v2/organizations/${organizationKey}/all-time-commits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2OrganizationsCommitsDetail
 * @summary Gets the recruits who are committed based on the team, status, year, sport
 * @request GET:/public/rdb/v2/organizations/{orgKey}/commits
 */
export const rdbV2OrganizationsCommitsDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV2OrganizationsCommitsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemResponsePagedData>({
      url: `/public/rdb/v2/organizations/${orgKey}/commits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2OrganizationsDecommitsDetail
 * @summary Gets the decommits based on the team, status, year, sport
 * @request GET:/public/rdb/v2/organizations/{orgKey}/decommits
 */
export const rdbV2OrganizationsDecommitsDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV2OrganizationsDecommitsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemResponsePagedData>({
      url: `/public/rdb/v2/organizations/${orgKey}/decommits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2OrganizationsOffersDetail
 * @summary Gets the recruits who have offers based on the team, status, year, sport
 * @request GET:/public/rdb/v2/organizations/{orgKey}/offers
 */
export const rdbV2OrganizationsOffersDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV2OrganizationsOffersDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemResponsePositionResponseRelatedPagedDataPagedData>({
      url: `/public/rdb/v2/organizations/${orgKey}/offers`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2OrganizationsTransfersDetail
 * @summary Gets the transfers based on the team, year, sport
 * @request GET:/public/rdb/v2/organizations/{orgKey}/transfers
 */
export const rdbV2OrganizationsTransfersDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV2OrganizationsTransfersDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemResponsePagedDataPagedData>({
      url: `/public/rdb/v2/organizations/${orgKey}/transfers`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Recruits
 * @name RdbV2OrganizationsVisitsDetail
 * @summary Gets the recruits who have visits based on the team, status, year, sport
 * @request GET:/public/rdb/v2/organizations/{orgKey}/visits
 */
export const rdbV2OrganizationsVisitsDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: RdbV2OrganizationsVisitsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerItemVisitResponseRelatedVisitDateRelatedPagedDataPagedData>({
      url: `/public/rdb/v2/organizations/${orgKey}/visits`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name RdbV2PeoplePersonConnectionsDetail
 * @summary Gets Person Connection by Person Key
 * @request GET:/public/rdb/v2/people/{personKey}/person-connections
 */
export const rdbV2PeoplePersonConnectionsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/public/rdb/v2/people/${personKey}/person-connections`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags RankingControllerV
 * @name RdbV2PlayerAllRankingsDetail
 * @summary Gets all types of rating and ranking for the given sport key
 * @request GET:/public/rdb/v2/player/{personKey}/all-rankings
 */
export const rdbV2PlayerAllRankingsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV2PlayerAllRankingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PlayerOrgRatingAllResponsePagedData>({
      url: `/public/rdb/v2/player/${personKey}/all-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Rpm
 * @name RdbV2UsersPredictionsDetail
 * @request GET:/public/rdb/v2/users/{userKey}/predictions
 */
export const rdbV2UsersPredictionsDetail = (
  client: AxiosInstance,
  { userKey, ...query }: RdbV2UsersPredictionsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RpmPickListPredictionUserProfileResponseRecordRelatedPagedData>({
      url: `/public/rdb/v2/users/${userKey}/predictions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2DealsList
 * @summary Public: Gets all NIL Deals
 * @request GET:/public/v2/deals
 */
export const v2DealsList = (
  client: AxiosInstance,
  query: V2DealsListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealResponsePagedData>({
      url: `/public/v2/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2OrganizationsDealsDetail
 * @summary Show all commits and rostered players for a specific team that have deals.
 * @request GET:/public/v2/organizations/{organizationKey}/deals
 */
export const v2OrganizationsDealsDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: V2OrganizationsDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealResponsePagedData>({
      url: `/public/v2/organizations/${organizationKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2PeopleDealsDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/public/v2/people/{personKey}/deals
 */
export const v2PeopleDealsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V2PeopleDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealResponsePagedData>({
      url: `/public/v2/people/${personKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2PlayersDealsDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/public/v2/players/{personKey}/deals
 */
export const v2PlayersDealsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V2PlayersDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealResponsePagedData>({
      url: `/public/v2/players/${personKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
