import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AdminSiteMetricsResponse {
  metrics?: SiteMetricsResponse[] | null;
  type?: string;
}

export interface ArticleMetrics {
  channelArticlesCount?: MetricDetail;
  freeCount?: MetricDetail;
  messageBoardCount?: MetricDetail;
  totalArticles?: MetricDetail;
  vipCount?: MetricDetail;
  writerCounts?: MetricDetail[] | null;
  writerMessageBoardCount?: MetricDetail[] | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface ChannelMetrics {
  adRevDirectDisplay?: MetricDetail;
  adRevExchangeDisplay?: MetricDetail;
  adRevVideoDirectDisplay?: MetricDetail;
  adRevVideoExchangeDisplay?: MetricDetail;
  directImpsDisplay?: MetricDetail;
  directImpsVideo?: MetricDetail;
  endOfMonthSubscriptions?: MetricDetail;
  exchangeImpsDisplay?: MetricDetail;
  exchangeImpsVideo?: MetricDetail;
  pageviews?: MetricDetail;
  pps?: MetricDetail;
  totalDisplayRevenue?: MetricDetail;
}

export interface DashboardAdRevenueMetric {
  /** @format double */
  adRevenueDirectDisplay: number;
  /** @format double */
  adRevenueExchangeDisplay: number;
  /** @format double */
  adRevenueVideoDirectDisplay: number;
  /** @format double */
  adRevenueVideoExchangeDisplay: number;
  /** @format double */
  compAdRevenueDirectDisplay: number | null;
  /** @format double */
  compAdRevenueExchangeDisplay: number | null;
  /** @format double */
  compAdRevenueVideoDirectDisplay: number | null;
  /** @format double */
  compAdRevenueVideoExchangeDisplay: number | null;
  /** @format date-time */
  date: string | null;
}

export interface DashboardArticleMetric {
  /** @format double */
  compFree: number | null;
  /** @format double */
  compPremium: number | null;
  /** @format date-time */
  date: string | null;
  /** @format double */
  free: number;
  /** @format double */
  premium: number;
}

export interface DashboardChannelMetric {
  /** @format double */
  compPPS: number | null;
  /** @format double */
  compPageViews: number | null;
  /** @format double */
  compRevenue: number | null;
  /** @format date-time */
  date: string | null;
  /** @format double */
  pageViews: number;
  /** @format double */
  pps: number;
  /** @format double */
  revenue: number;
}

export interface DashboardMediaMetric {
  /** @format double */
  adRevenueDirect: number;
  /** @format double */
  adRevenueExchange: number;
  /** @format double */
  compAdRevenueDirect: number | null;
  /** @format double */
  compAdRevenueExchange: number | null;
  /** @format date-time */
  date: string | null;
}

export interface DashboardMessagePostsMetric {
  /** @format double */
  compMessageBoardPosts: number | null;
  /** @format date-time */
  date: string | null;
  /** @format double */
  messageBoardPosts: number;
}

export interface DashboardRevenueMetric {
  /** @format double */
  adRevenueDirect: number;
  /** @format double */
  adRevenueExchange: number;
  /** @format double */
  allOtherRevenue: number;
  /** @format double */
  compAdRevenueDirect: number | null;
  /** @format double */
  compAdRevenueExchange: number | null;
  /** @format double */
  compAllOtherRevenue: number | null;
  /** @format double */
  compSubRevenue: number | null;
  /** @format date-time */
  date: string | null;
  /** @format double */
  subRevenue: number;
}

export interface DashboardSessionMetric {
  /** @format double */
  compValue: number | null;
  /** @format int64 */
  id: number;
  label: string;
  /** @format double */
  value: number;
}

export interface DashboardSubscriptionMetric {
  /** @format double */
  cancels: number;
  /** @format double */
  compCancels: number | null;
  /** @format double */
  compNewSignups: number | null;
  /** @format date-time */
  date: string | null;
  /** @format double */
  newSignups: number;
}

export interface DashboardTrafficMetric {
  /** @format double */
  compPageViews: number | null;
  /** @format double */
  compPps: number | null;
  /** @format date-time */
  date: string | null;
  /** @format double */
  pageViews: number;
  /** @format double */
  pps: number;
}

export interface Data {
  dataPoints?: DataPoint[];
  id?: string;
  label?: string;
}

export interface DataPoint {
  /** @format date-time */
  x?: string | null;
  /** @format double */
  y?: number | null;
}

export interface MetricDashboardResponse {
  /** @format double */
  goalMetric?: number | null;
  lines?: Data[];
  /** @format double */
  totalMetric?: number | null;
}

export interface MetricDetail {
  /** @format double */
  compareValue?: number | null;
  /** @format double */
  delta?: number | null;
  displayName?: string | null;
  /** @format double */
  ecpm?: number | null;
  increase?: boolean | null;
  /** @format double */
  percentChange?: number | null;
  /** @format double */
  percentOfTotal?: number | null;
  type: MetricType;
  /** @format double */
  value?: number | null;
}

export enum MetricType {
  Revenue = 'Revenue',
  YouTube = 'YouTube',
  Podcasts = 'Podcasts',
  ArticlesAndBoards = 'ArticlesAndBoards',
  Subscription = 'Subscription',
  Traffic = 'Traffic',
  Channel = 'Channel',
  Article = 'Article',
  Note = 'Note',
}

export interface MetricsResponse {
  articleMetrics?: ArticleMetrics;
  channelMetrics?: ChannelMetrics;
  notes?: SiteMetricsResponse[] | null;
  podcastMetrics?: PodcastMetrics;
  revenueMetrics?: RevenueMetrics;
  subscriptionMetrics?: SubscriptionMetrics;
  trafficMetrics?: TrafficMetrics;
  youTubeMetrics?: YouTubeMetrics;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PodcastMetrics {
  adRevVideoDirect?: MetricDetail;
  adRevVideoExchange?: MetricDetail;
  downloads?: MetricDetail;
  numberofPodcasts?: MetricDetail;
  totalPodcastRevenue?: MetricDetail;
}

export interface ProcessAdsRequest {
  daily: boolean;
  /** @format date-time */
  endDate: string;
  /** @format date-time */
  startDate: string;
}

export interface ProcessPublisherReportRequest {
  daily: boolean;
  dataSet: PublisherReportDataSet;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  startDate: string;
}

export enum PublisherReportDataSet {
  Articles = 'Articles',
  Connatix = 'Connatix',
  GA = 'GA',
  Notes = 'Notes',
  Revenue = 'Revenue',
  Subscription = 'Subscription',
}

export interface RenovateSiteDetail {
  /** @format int64 */
  forumsAssetOneKey?: number | null;
  /** @format int64 */
  forumsAssetTwoKey?: number | null;
  forumsDescription?: string | null;
  forumsTitle?: string | null;
  /** @format int64 */
  genericAssetKey?: number | null;
  genericDescription?: string | null;
  genericTitle?: string | null;
  /** @format int64 */
  key?: number | null;
  siteBullets?: string[] | null;
}

export interface RenovateSiteRequest {
  allowSubs: boolean;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  /** @format int64 */
  alternateLogoKey?: number | null;
  /** @format int64 */
  categoryKey?: number | null;
  /** @format int64 */
  defaultAssetKey?: number | null;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  jwPlaylistId?: string | null;
  /** @format int32 */
  key?: number | null;
  keyPersons?: number[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  /**
   * @minLength 0
   * @maxLength 15
   */
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export enum ReportType {
  Sessions = 'Sessions',
  PageViews = 'PageViews',
  VideoPlays = 'VideoPlays',
  MessageBoardPosts = 'MessageBoardPosts',
  ContentPosted = 'ContentPosted',
  SubRev = 'SubRev',
  AdRev = 'AdRev',
  VideoRev = 'VideoRev',
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RevenueMetrics {
  adRevDirect?: MetricDetail;
  adRevExchange?: MetricDetail;
  allOtherRev?: MetricDetail;
  channelRev?: MetricDetail;
  grandTotal?: MetricDetail;
  subRev?: MetricDetail;
  totalTeamSiteRev?: MetricDetail;
}

export interface RevenueReportMetric {
  /** @format double */
  metric: number;
  metricIncreased: boolean;
  /** @format double */
  perMetric: number;
  perMetricIncreased: boolean;
  /** @format double */
  revenue: number;
  revenueIncreased: boolean;
}

export interface SimpleSiteResponseRecord {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: AssetResponse;
  /** @format int64 */
  alternateLogoKey?: number | null;
  /** @format int64 */
  categoryKey?: number | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  jwPlaylistId?: string | null;
  /** @format int32 */
  key: number;
  keyPersons?: SimpleUserResponse[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export interface SimpleSiteResponseRecordPagedData {
  list?: SimpleSiteResponseRecord[];
  pagination?: Pagination;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export interface SiteAnalyticsResponse {
  analyticsPerWriter?: SiteAnalyticsWriterResponse[] | null;
  /** @format int32 */
  freeCount?: number | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  /** @format int32 */
  totalArticles?: number | null;
  /** @format int32 */
  vipCount?: number | null;
}

export interface SiteAnalyticsWriterResponse {
  /** @format int32 */
  articleCount?: number | null;
  /** @format int32 */
  freeCount?: number | null;
  /** @format int32 */
  messageBoardCount?: number | null;
  name?: string | null;
  /** @format int32 */
  premiumCount?: number | null;
  /** @format int32 */
  replyCount?: number | null;
  siteName?: string | null;
  /** @format int32 */
  topicCount?: number | null;
  /** @format int64 */
  writerKey: number;
}

export interface SiteDetailResponse {
  forumsAssetOne?: AssetResponse;
  forumsAssetTwo?: AssetResponse;
  forumsDescription?: string | null;
  forumsTitle?: string | null;
  genericAssetOne?: AssetResponse;
  genericDescription?: string | null;
  genericTitle?: string | null;
  /** @format int64 */
  key: number;
  siteBullets?: string[] | null;
}

export interface SiteMetricGoalReportRow {
  /** @format double */
  goalValue?: number | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  metricKey: number;
  /** @format double */
  originalValue?: number | null;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  siteMetricDate?: string | null;
  siteMetricName?: string | null;
  siteName?: string | null;
}

export interface SiteMetricRevenueReport {
  adRevenue?: RevenueReportMetric;
  subscriptionRevenue?: RevenueReportMetric;
  /** @format double */
  total: number;
  videoRevenue?: RevenueReportMetric;
}

export interface SiteMetricsResponse {
  /** @format date-time */
  date?: string | null;
  /** @format double */
  editedValue?: number | null;
  /** @format int64 */
  key: number;
  metricName?: string | null;
  metricType?: string | null;
  note?: string | null;
  /** @format double */
  originalValue?: number | null;
  /** @format int32 */
  siteKey: number;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SiteYouTubeProfileResponse {
  channelId?: string;
  channelName?: string;
  description?: string;
  highResThumbnail?: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  subscriberCount: number;
  uploadPlaylistId?: string;
  /** @format int64 */
  videoCount: number;
  /** @format int64 */
  viewCount: number;
}

export interface SiteYouTubeVideoResponse {
  /** @format int64 */
  commentCount?: number | null;
  /** @format int64 */
  datePublishedUtc?: number | null;
  description?: string | null;
  highResThumbnailUrl?: string | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  likeCount?: number | null;
  title?: string | null;
  videoId?: string | null;
  videoLength?: string | null;
  /** @format int64 */
  viewCount?: number | null;
}

export interface SiteYouTubeVideoResponseSiteYouTubeProfileResponseRelatedPagedData {
  list?: SiteYouTubeVideoResponse[];
  pagination?: Pagination;
  relatedModel?: SiteYouTubeProfileResponse;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface SubscriptionMetrics {
  accruedSubscriptionRevenue?: MetricDetail;
  annualSubscriptions?: MetricDetail;
  begOfMonthNetActives?: MetricDetail;
  cancels?: MetricDetail;
  cashSubscriptionCollections?: MetricDetail;
  endOfMonthNetActives?: MetricDetail;
  giftSubscriptions?: MetricDetail;
  monthlySubscriptions?: MetricDetail;
  newNetActives?: MetricDetail;
  newSignUps?: MetricDetail;
  oregonLegacySubCash?: MetricDetail;
  otherSubscriptions?: MetricDetail;
  percentageAnnual?: MetricDetail;
  premiumAnnualSubscriptions?: MetricDetail;
  premiumMonthlySubscriptions?: MetricDetail;
  semiAnnualSubscriptions?: MetricDetail;
}

export interface TrafficMetrics {
  adRevDirectDisplay?: MetricDetail;
  adRevExchangeDisplay?: MetricDetail;
  adRevVideoDirectDisplay?: MetricDetail;
  adRevVideoExchangeDisplay?: MetricDetail;
  allOtherSources?: MetricDetail;
  direct?: MetricDetail;
  directImpsDisplay?: MetricDetail;
  directImpsVideo?: MetricDetail;
  exchangeImpsDisplay?: MetricDetail;
  exchangeImpsVideo?: MetricDetail;
  facebook?: MetricDetail;
  newsletter?: MetricDetail;
  organicSearch?: MetricDetail;
  pageviews?: MetricDetail;
  pps?: MetricDetail;
  referral?: MetricDetail;
  totalDisplayRevenue?: MetricDetail;
  totalSessions?: MetricDetail;
  twitter?: MetricDetail;
}

export interface V2SitesAdminImportUpdatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V2SitesAnalyticsListParams {
  /** @format date-time */
  endDate?: string;
  /** @format int64 */
  siteKey?: number;
  /** @format date-time */
  startDate?: string;
}

export interface V2SitesDashboardAdRevenueDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardArticlesDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardChannelDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardGraphListParams {
  /** @format date-time */
  compareEndDate: string;
  /** @format date-time */
  compareStartDate: string;
  /** @format date-time */
  endDate: string;
  metricType: MetricType;
  reportType: ReportType;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardKpisListParams {
  /** @format date-time */
  compareEndDate: string;
  /** @format date-time */
  compareStartDate: string;
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardMessageBoardPostsDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardPodcastsDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardRevenueDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardSessionsDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardSubscriptionsDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardTrafficMetricsDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesDashboardYoutubeDetailParams {
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesListParams {
  slug?: string;
}

export interface V2SitesMetricGoalsReportDetailParams {
  /**
   * Date Range (to Date) - Required
   * @format date-time
   */
  endDate?: string;
  /**
   * Site that you want to download the report for
   * @format int32
   */
  siteKey: number;
  /**
   * Date Range (from date) - Required
   * @format date-time
   */
  startDate?: string;
}

export interface V2SitesMetricGoalsReportUpdatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V2SitesMetricRevenueReportListParams {
  /** @format date-time */
  compareEndDate: string;
  /** @format date-time */
  compareStartDate: string;
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate: string;
}

export interface V2SitesMetricsAdminListParams {
  /** @format date-time */
  compareEndDate?: string;
  /** @format date-time */
  compareStartDate?: string;
  /** @format date-time */
  endDate: string;
  metricType?: MetricType;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate?: string;
}

export interface V2SitesMetricsAdminUpdateParams {
  reason?: string;
  /** @format int32 */
  siteKey: number;
  /** @format int64 */
  siteMetricKey: number;
  /** @format double */
  value?: number;
}

export interface V2SitesMetricsListParams {
  /** @format date-time */
  compareEndDate?: string;
  /** @format date-time */
  compareStartDate?: string;
  /** @format date-time */
  endDate: string;
  metricType?: MetricType;
  /** @format int32 */
  siteKey: number;
  /** @format date-time */
  startDate?: string;
}

export interface V2SitesMetricsProcessAdsCreateParams {
  daily?: boolean;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  startDate?: string;
}

export interface V2SitesMetricsProcessAdsCreatePayload {
  /** @format binary */
  adsFile?: File;
}

export interface V2SitesYoutubeDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  key: number;
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface YouTubeMetrics {
  adRevVideoDirect?: MetricDetail;
  adRevVideoExchange?: MetricDetail;
  begOfMonthSubscriptions?: MetricDetail;
  endOfMonthSubscriptions?: MetricDetail;
  exchangeVideoImps?: MetricDetail;
  netNewSubscriptions?: MetricDetail;
  numberOfVideosPublished?: MetricDetail;
  totalYouTubeRevenue?: MetricDetail;
  transactions?: MetricDetail;
  views?: MetricDetail;
  watchTime?: MetricDetail;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Site
 * @name V2SitesAdminDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/sites/v2/sites/{key}/admin
 * @secure
 */
export const v2SitesAdminDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<SimpleSiteResponseRecord>({
      url: `/sites/v2/sites/${key}/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesAdminImportUpdate
 * @summary Accepts a file (csv) that will set metrics for a particular site
 * @request PUT:/sites/v2/sites/{siteKey}/admin-import
 * @secure
 */
export const v2SitesAdminImportUpdate = (
  client: AxiosInstance,
  siteKey: number,
  data: V2SitesAdminImportUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/sites/v2/sites/${siteKey}/admin-import`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesAnalyticsList
 * @summary Gets author analytics per site
 * @request GET:/sites/v2/sites/analytics
 * @secure
 */
export const v2SitesAnalyticsList = (
  client: AxiosInstance,
  query: V2SitesAnalyticsListParams,
  params: RequestParams = {},
) =>
  client
    .request<SiteAnalyticsResponse>({
      url: `/sites/v2/sites/analytics`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardAdRevenueDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/ad-revenue
 * @secure
 */
export const v2SitesDashboardAdRevenueDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardAdRevenueDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardAdRevenueMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/ad-revenue`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardArticlesDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/articles
 * @secure
 */
export const v2SitesDashboardArticlesDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardArticlesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardArticleMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/articles`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardChannelDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/channel
 * @secure
 */
export const v2SitesDashboardChannelDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardChannelDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardChannelMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/channel`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardGraphList
 * @summary Returns dashboard graphs for Publisher Reporting Data
Displays Audience as well as Content graphics for metrics reporting
 * @request GET:/sites/v2/sites/dashboard-graph
 * @secure
 */
export const v2SitesDashboardGraphList = (
  client: AxiosInstance,
  query: V2SitesDashboardGraphListParams,
  params: RequestParams = {},
) =>
  client
    .request<MetricDashboardResponse>({
      url: `/sites/v2/sites/dashboard-graph`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardKpisList
 * @summary Get Top level KPI metrics for the dashboard
 * @request GET:/sites/v2/sites/dashboard-kpis
 * @secure
 */
export const v2SitesDashboardKpisList = (
  client: AxiosInstance,
  query: V2SitesDashboardKpisListParams,
  params: RequestParams = {},
) =>
  client
    .request<MetricDetail[]>({
      url: `/sites/v2/sites/dashboard-kpis`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardMessageBoardPostsDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/message-board-posts
 * @secure
 */
export const v2SitesDashboardMessageBoardPostsDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardMessageBoardPostsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardMessagePostsMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/message-board-posts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardPodcastsDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/podcasts
 * @secure
 */
export const v2SitesDashboardPodcastsDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardPodcastsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardMediaMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/podcasts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardRevenueDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/revenue
 * @secure
 */
export const v2SitesDashboardRevenueDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardRevenueDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardRevenueMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/revenue`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardSessionsDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/sessions
 * @secure
 */
export const v2SitesDashboardSessionsDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardSessionsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardSessionMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/sessions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardSubscriptionsDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/subscriptions
 * @secure
 */
export const v2SitesDashboardSubscriptionsDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardSubscriptionsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardSubscriptionMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/subscriptions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardTrafficMetricsDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/traffic-metrics
 * @secure
 */
export const v2SitesDashboardTrafficMetricsDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardTrafficMetricsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardTrafficMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/traffic-metrics`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDashboardYoutubeDetail
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/dashboard/{siteKey}/youtube
 * @secure
 */
export const v2SitesDashboardYoutubeDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesDashboardYoutubeDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<DashboardMediaMetric[]>({
      url: `/sites/v2/sites/dashboard/${siteKey}/youtube`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDetail
 * @request GET:/sites/v2/sites/{key}
 */
export const v2SitesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<SimpleSiteResponseRecord>({
      url: `/sites/v2/sites/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDetailsAdminDetail
 * @summary Gets site details for a given site
 * @request GET:/sites/v2/sites/{siteKey}/details/admin
 * @secure
 */
export const v2SitesDetailsAdminDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SiteDetailResponse>({
      url: `/sites/v2/sites/${siteKey}/details/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDetailsDetail
 * @summary Gets site details for a given site
 * @request GET:/sites/v2/sites/{siteKey}/details
 */
export const v2SitesDetailsDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SiteDetailResponse>({
      url: `/sites/v2/sites/${siteKey}/details`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesDetailsUpdate
 * @summary Add or Update a site detail
 * @request PUT:/sites/v2/sites/{siteKey}/details
 * @secure
 */
export const v2SitesDetailsUpdate = (
  client: AxiosInstance,
  siteKey: number,
  data: RenovateSiteDetail,
  params: RequestParams = {},
) =>
  client
    .request<SiteDetailResponse>({
      url: `/sites/v2/sites/${siteKey}/details`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesList
 * @request GET:/sites/v2/sites
 */
export const v2SitesList = (
  client: AxiosInstance,
  query: V2SitesListParams,
  params: RequestParams = {},
) =>
  client
    .request<SimpleSiteResponseRecordPagedData>({
      url: `/sites/v2/sites`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricGoalsReportDetail
 * @summary Returns the goals for site, in CSV format for the particular date range (from and to date)
 * @request GET:/sites/v2/sites/{siteKey}/metric-goals-report
 * @secure
 */
export const v2SitesMetricGoalsReportDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V2SitesMetricGoalsReportDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<SiteMetricGoalReportRow[]>({
      url: `/sites/v2/sites/${siteKey}/metric-goals-report`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricGoalsReportUpdate
 * @summary Accepts a file (csv) that will override the goals for a particular site
 * @request PUT:/sites/v2/sites/{siteKey}/metric-goals-report
 * @secure
 */
export const v2SitesMetricGoalsReportUpdate = (
  client: AxiosInstance,
  siteKey: number,
  data: V2SitesMetricGoalsReportUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/sites/v2/sites/${siteKey}/metric-goals-report`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricRevenueReportList
 * @summary  (Roles: developer, admin_publisher_dashboard, accounting_admin, publisher_dashboard)
 * @request GET:/sites/v2/sites/metric-revenue-report
 * @secure
 */
export const v2SitesMetricRevenueReportList = (
  client: AxiosInstance,
  query: V2SitesMetricRevenueReportListParams,
  params: RequestParams = {},
) =>
  client
    .request<SiteMetricRevenueReport>({
      url: `/sites/v2/sites/metric-revenue-report`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsAdminList
 * @summary Gets a list of sites that have metrics available
 * @request GET:/sites/v2/sites/metrics/admin
 * @secure
 */
export const v2SitesMetricsAdminList = (
  client: AxiosInstance,
  query: V2SitesMetricsAdminListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminSiteMetricsResponse[]>({
      url: `/sites/v2/sites/metrics/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsAdminUpdate
 * @summary Update Metric Value for a Site
 * @request PUT:/sites/v2/sites/metrics/admin
 * @secure
 */
export const v2SitesMetricsAdminUpdate = (
  client: AxiosInstance,
  query: V2SitesMetricsAdminUpdateParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminSiteMetricsResponse[]>({
      url: `/sites/v2/sites/metrics/admin`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsList
 * @summary Gets metrics per site
 * @request GET:/sites/v2/sites/metrics
 * @secure
 */
export const v2SitesMetricsList = (
  client: AxiosInstance,
  query: V2SitesMetricsListParams,
  params: RequestParams = {},
) =>
  client
    .request<MetricsResponse>({
      url: `/sites/v2/sites/metrics`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsProcessAdsCreate
 * @summary  (Roles: developer, accounting_admin)
 * @request POST:/sites/v2/sites/metrics/process-ads
 * @secure
 */
export const v2SitesMetricsProcessAdsCreate = (
  client: AxiosInstance,
  query: V2SitesMetricsProcessAdsCreateParams,
  data: V2SitesMetricsProcessAdsCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/sites/v2/sites/metrics/process-ads`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsProcessAdsLocCreate
 * @summary  (Roles: developer, accounting_admin)
 * @request POST:/sites/v2/sites/metrics/process-ads-loc
 * @secure
 */
export const v2SitesMetricsProcessAdsLocCreate = (
  client: AxiosInstance,
  data: ProcessAdsRequest,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/sites/v2/sites/metrics/process-ads-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsProcessDataLocCreate
 * @summary  (Roles: developer, accounting_admin)
 * @request POST:/sites/v2/sites/{siteKey}/metrics/process-data-loc
 * @secure
 */
export const v2SitesMetricsProcessDataLocCreate = (
  client: AxiosInstance,
  siteKey: number,
  data: ProcessPublisherReportRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/sites/v2/sites/${siteKey}/metrics/process-data-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsProcessDataLocCreate2
 * @summary  (Roles: developer, accounting_admin)
 * @request POST:/sites/v2/sites/metrics/process-data-loc
 * @originalName v2SitesMetricsProcessDataLocCreate
 * @duplicate
 * @secure
 */
export const v2SitesMetricsProcessDataLocCreate2 = (
  client: AxiosInstance,
  data: ProcessPublisherReportRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/sites/v2/sites/metrics/process-data-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesMetricsSitesAvailableList
 * @summary Gets a list of sites that have metrics available
 * @request GET:/sites/v2/sites/metrics/sites-available
 * @secure
 */
export const v2SitesMetricsSitesAvailableList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SimpleSiteResponseRecord[]>({
      url: `/sites/v2/sites/metrics/sites-available`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4)
 * @request PUT:/sites/v2/sites/renovate
 * @secure
 */
export const v2SitesRenovateUpdate = (
  client: AxiosInstance,
  data: RenovateSiteRequest,
  params: RequestParams = {},
) =>
  client
    .request<SimpleSiteResponseRecord>({
      url: `/sites/v2/sites/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Site
 * @name V2SitesYoutubeDetail
 * @request GET:/sites/v2/sites/{key}/youtube
 */
export const v2SitesYoutubeDetail = (
  client: AxiosInstance,
  { key, ...query }: V2SitesYoutubeDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<SiteYouTubeVideoResponseSiteYouTubeProfileResponseRelatedPagedData>(
      {
        url: `/sites/v2/sites/${key}/youtube`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);
