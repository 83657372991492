import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export type AbstractRankingMetaRes = EfiMetaRes & {
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  position?: PositionResponse;
  state?: StateResponseRecord;
  /** @format int64 */
  timestamp: number;
  type?: string;
};

export enum AnnouncementImageGraphicType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  TopSchools = 'TopSchools',
  DecisionDate = 'DecisionDate',
  OfficialVisit = 'OfficialVisit',
  Offers = 'Offers',
}

export enum AnnouncementImageStatus {
  Requested = 'Requested',
  InProgress = 'InProgress',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
}

export type ArticleMetaRes = EfiMetaRes & {
  authorName?: string | null;
  authorSlug?: string | null;
  featuredImage?: ImageResponse;
  fullPath?: string | null;
  isPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format date-time */
  postDateGmt: string;
  primaryCategory?: SlimCategoryResponse;
  slug?: string | null;
  status?: string | null;
  title?: string | null;
};

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export type BoltMetaRes = EfiMetaRes & {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  author?: SimpleAuthorResponse;
  /** @format int64 */
  authorKey: number;
  body?: string | null;
  /** @format date-time */
  created: string;
  headline?: SimpleHeadlineResponse;
  headlineAsset?: AssetResponse;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  title?: string;
  /** @format date-time */
  updated?: string | null;
};

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export type EfiMetaRes = object;

export enum FeedItemTypes {
  Unknown = 'Unknown',
  Article = 'Article',
  NilValuation = 'NilValuation',
  NilDeal = 'NilDeal',
  IndustryRanking = 'IndustryRanking',
  GraphicEdit = 'GraphicEdit',
  ServiceRanking = 'ServiceRanking',
  Bolt = 'Bolt',
}

export interface FileResponse {
  key?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  name?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  tag?: number | null;
}

export type GraphicEditMetaRes = EfiMetaRes & {
  completedImage?: FileResponse;
  graphicType: AnnouncementImageGraphicType;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  requestedTimestamp: number;
  status: AnnouncementImageStatus;
};

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export type IndustryRankingMetaRes = AbstractRankingMetaRes & {
  industryRanking?: IndustryRankingRes;
};

export interface IndustryRankingRes {
  on3?: ServiceRankingRes;
  espn?: ServiceRankingRes;
  fiveStarPlus: boolean;
  industryRanking?: ServiceRankingRes;
  /** @format int64 */
  key: number;
  nearlyFiveStarPlus: boolean;
  rankingType: RankingType;
  rivals?: ServiceRankingRes;
  tfs?: ServiceRankingRes;
  /** @format int64 */
  timestamp: number;
}

export type NilValuationMetaRes = EfiMetaRes & {
  /** @format date-time */
  date: string;
  increase?: boolean | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  previousValuation?: number | null;
  /** @format int64 */
  valuation: number;
  /** @format int64 */
  valuationChange: number;
  /** @format double */
  valuationChangePercent: number;
};

export interface OrganizationFeedItemRes {
  /** @format int64 */
  date: number;
  /** @format int64 */
  efiKey: number;
  feedType: FeedItemTypes;
  /** @format int64 */
  key: number;
  meta?:
    | EfiMetaRes
    | ArticleMetaRes
    | NilValuationMetaRes
    | GraphicEditMetaRes
    | AbstractRankingMetaRes
    | ServiceRankingMetaRes
    | IndustryRankingMetaRes
    | BoltMetaRes
    | null;
  /** @format int32 */
  organizationKey: number;
  /** @format date-time */
  storedDate: string;
  /** @format int64 */
  typeKey: number;
}

export interface OrganizationFeedItemResSimpleOrganizationResponseRelatedPagedData {
  list?: OrganizationFeedItemRes[];
  pagination?: Pagination;
  relatedModel?: SimpleOrganizationResponse;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonFeedItemRes {
  /** @format int64 */
  date: number;
  /** @format int64 */
  efiKey: number;
  feedType: FeedItemTypes;
  /** @format int64 */
  key: number;
  meta?:
    | EfiMetaRes
    | ArticleMetaRes
    | NilValuationMetaRes
    | GraphicEditMetaRes
    | AbstractRankingMetaRes
    | ServiceRankingMetaRes
    | IndustryRankingMetaRes
    | BoltMetaRes
    | null;
  /** @format int64 */
  personKey: number;
  /** @format date-time */
  storedDate: string;
  /** @format int64 */
  typeKey: number;
}

export interface PersonFeedItemResSimplePersonResponseRelatedPagedData {
  list?: PersonFeedItemRes[];
  pagination?: Pagination;
  relatedModel?: SimplePersonResponse;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface RankChangeRes {
  type: PlayerRatingChange;
  /** @format int32 */
  value?: number | null;
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
}

export interface RatingChangeRes {
  type: PlayerRatingChange;
  /** @format double */
  value?: number | null;
}

export interface RdbV1OrganizationsFeedDetailParams {
  /** @format int32 */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface RdbV1PeopleFeedDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface RelatedFeedInfoRes {
  people?: SimpleFeedItemPersonRes[];
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export type ServiceRankingMetaRes = AbstractRankingMetaRes & {
  serviceRanking?: ServiceRankingRes;
};

export interface ServiceRankingRes {
  nationalChange?: RankChangeRes;
  /** @format int32 */
  nationalRank?: number | null;
  positionChange?: RankChangeRes;
  /** @format int32 */
  positionRank?: number | null;
  previousRanking?: ServiceRankingRes;
  /** @format double */
  rating?: number | null;
  ratingChange?: RatingChangeRes;
  /** @format int32 */
  stars?: number | null;
  stateChange?: RankChangeRes;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export interface SimpleAuthorResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  slug?: string | null;
}

export interface SimpleFeedItemPersonRes {
  person?: SimplePersonResponse;
  personAsset?: AssetResponse;
  /** @format int64 */
  personKey: number;
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimpleOrganizationResponse {
  abbreviation?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  organizationType: OrganizationType;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SlimCategoryResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface UserFeedItemRes {
  /** @format int64 */
  date: number;
  /** @format int64 */
  efiKey: number;
  feedType: FeedItemTypes;
  /** @format int64 */
  key: number;
  meta?:
    | EfiMetaRes
    | ArticleMetaRes
    | NilValuationMetaRes
    | GraphicEditMetaRes
    | AbstractRankingMetaRes
    | ServiceRankingMetaRes
    | IndustryRankingMetaRes
    | BoltMetaRes
    | null;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageOrder: number;
  personKeys?: number[];
  /** @format date-time */
  storedDate: string;
  /** @format int64 */
  typeKey: number;
  /** @format int64 */
  userKey: number;
}

export interface UserFeedItemResRelatedFeedInfoResRelatedPagedData {
  list?: UserFeedItemRes[];
  pagination?: Pagination;
  relatedModel?: RelatedFeedInfoRes;
}

export interface V1UsersFeedDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
  /** @format int64 */
  userKey: number;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags OrganizationFeed
 * @name RdbV1OrganizationsFeedDetail
 * @summary  (Roles: registered user)
 * @request GET:/public/rdb/v1/organizations/{organizationKey}/feed
 * @secure
 */
export const rdbV1OrganizationsFeedDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: RdbV1OrganizationsFeedDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationFeedItemResSimpleOrganizationResponseRelatedPagedData>(
      {
        url: `/public/rdb/v1/organizations/${organizationKey}/feed`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name RdbV1PeopleFeedDetail
 * @summary  (Roles: registered user)
 * @request GET:/public/rdb/v1/people/{personKey}/feed
 * @secure
 */
export const rdbV1PeopleFeedDetail = (
  client: AxiosInstance,
  { personKey, ...query }: RdbV1PeopleFeedDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonFeedItemResSimplePersonResponseRelatedPagedData>({
      url: `/public/rdb/v1/people/${personKey}/feed`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags UserFeed
 * @name V1UsersFeedDetail
 * @summary  (Roles: registered user)
 * @request GET:/users/v1/users/{userKey}/feed
 * @secure
 */
export const v1UsersFeedDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1UsersFeedDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<UserFeedItemResRelatedFeedInfoResRelatedPagedData>({
      url: `/users/v1/users/${userKey}/feed`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
